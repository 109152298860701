
/**
 *  Component containing the list of previous test sessions of the logged in user.
 */
import {
  DateTimeFormatter,
  ZonedDateTime
} from "@js-joda/core";
import "@js-joda/timezone";

let util = {

  /**
   * Get the name of a test
   * @param testType test type
   * @returns string name corresponding to the test type, or the test type itself if there is no mapping
   */
  getTestTypeName(testType) {
    let nameMap = {
      "AMT_CONNECT" : "AMT Connectie",
      "AMT_TOEGANG" : "AMT Toegang",
      "AMT_BEHEER_NETWERK" : "AMT Beheer Netwerk",
      "ICP_CON_CONNECT" : "ICP CON Connectie",
      "ICP_CON_DEFAULTS" : "ICP CON Standaard waarden",
      "IROSE2_CONNECT" : "iRose2 Connectie",
      "IROSE2_KERNEL" : "iRose2 Kernel",
      "IROSE2_LUSDETECTIE" : "iRose2 Lusdetectie",
      "IROSE2_LUSSTATUS" : "iRose2 Lusstatus",
      "IROSE2_RECONNECT" : "iRose2 Herstarten connectie",
      "SLD4_CONNECT" : "SLD4 Connectie",
      "SLD4_RECONNECT" : "SLD4 Herstarten Connectie",
      "LD16_CONNECT" : "LD16 Connectie",
      "LD16_RECONNECT" : "LD16 Herstarten Connectie",
      "LAN_SWITCH_PING_1" : "LAN Switch 1 Connectie",
      "LAN_SWITCH_PING_2" : "LAN Switch 2 Connectie",
      "MSI_BEELDSTAND" : "MSI Beeldstand",
      "MSI_CONNECT" : "MSI Connectie",
      "MUS_BEELDSTAND" : "MUS Beeldstand",
      "MUS_CONNECT" : "MUS Connectie",
      "MUS_WIEBELTEST" : "MUS Wiebeltest",
      "PROFIBUS_GATEWAY_CONNECT" : "Profibus Gateway Connectie",
      "PROFIBUS_GATEWAY_RECONNECT" : "Profibus Gateway Herstarten connectie",
      "UPS_COMMUNICATIE" : "UPS Communicatie",
      "UPS_HOOFDVOEDING" : "UPS Hoofdvoeding",
      "UPS_NOODVOEDING" : "UPS Noodvoeding",
      "UPS_RESTART" : "UPS Automatisch herstarten",
      "FALCON_RADAR_PING" : "Falcon Radar Ping Test",
      "KOPER_KOPPELVLAK_PLC_PING" : "Koperkoppelvlak PLC Ping Test",
      "KOPER_KOPPELVLAK_DSO_PING" : "Koperkoppelvlak Ontvanger Ping Test",
      "KOPER_KOPPELVLAK_DSZ_PING" : "Koperkoppelvlak Zender Ping Test"
    }
    let testName = nameMap[testType];
    if (testName == null) {
      console.warn("No name mapped for testType: " + testType);
      return testType;
    } else {
      return testName;
    }
  },

  sortTests(tests) {
      var sortOrder = ['AMT_CONNECT',
      'AMT_TOEGANG',
      'AMT_BEHEER_NETWERK',
      'LAN_SWITCH_PING_1',
      'LAN_SWITCH_PING_2',
      'ICP_CON_CONNECT',
      'ICP_CON_DEFAULTS',
      'IROSE2_CONNECT',
      'IROSE2_KERNEL',
      'IROSE2_LUSDETECTIE',
      'IROSE2_LUSSTATUS',
      'IROSE2_RECONNECT',
      'SLD4_CONNECT',
      'SLD4_RECONNECT',
      'LD16_CONNECT',
      'LD16_RECONNECT',
      'MSI_BEELDSTAND',
      'MSI_CONNECT',
      'MUS_BEELDSTAND',
      'MUS_CONNECT',
      'MUS_WIEBELTEST',
      'FALCON_RADAR_PING',
      'KOPER_KOPPELVLAK_PLC_PING',
      'KOPER_KOPPELVLAK_DSO_PING',
      'KOPER_KOPPELVLAK_DSZ_PING',
      'PROFIBUS_GATEWAY_CONNECT',
      'PROFIBUS_GATEWAY_RECONNECT',
      'UPS_COMMUNICATIE',
      'UPS_NOODVOEDING',
      'UPS_HOOFDVOEDING',
      'UPS_RESTART'];
    
    tests.sort(function(a, b) {
      return sortOrder.indexOf(a.testType) - sortOrder.indexOf(b.testType);
    });
  },

  parseDateTime(zonedDateTime) {
    let dtf = DateTimeFormatter.ofPattern("dd/MM/yyyy HH:mm:ss");
    return ZonedDateTime.parse(zonedDateTime).toLocalDateTime().format(dtf);
  },

  parseDate(zonedDateTime) {
    let dtf = DateTimeFormatter.ofPattern("dd/MM/yyyy");
    return ZonedDateTime.parse(zonedDateTime).toLocalDateTime().format(dtf);
  }
}
export default util;

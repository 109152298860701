var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Siemens C3 koperkoppelvlak configuratie")]),_c('KoperKoppelvlakConfig',{attrs:{"koperKoppelvlakken":_vm.koperKoppelvlakken,"show-hardware-id":false,"show-delete-button":true},on:{"koperKoppelvlakListUpdated":_vm.onListUpdated}}),_c('v-form',{ref:"form",staticClass:"mt-6",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"koperKoppelvlakHostPLC","label":"PLC Host","required":"","filled":"","disabled":""},model:{value:(_vm.hostPLC),callback:function ($$v) {_vm.hostPLC=$$v},expression:"hostPLC"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Het IP adres van de PLC van het koperkoppelvlak kan niet aangepast worden. Deze dient altijd 192.168.1.55 te zijn.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"id":"koperKoppelvlakHostDSO","items":_vm.hostDSOOptions,"label":"DSO Host","required":"","filled":""},model:{value:(_vm.hostDSO),callback:function ($$v) {_vm.hostDSO=$$v},expression:"hostDSO"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Het IP adres van de ontvanger van het koperkoppelvlak. Deze is optioneel. Deze dient altijd 192.168.1.56 te zijn.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"id":"koperKoppelvlakHostDSZ","items":_vm.hostDSZOptions,"label":"Host DSZ","required":"","filled":""},model:{value:(_vm.hostDSZ),callback:function ($$v) {_vm.hostDSZ=$$v},expression:"hostDSZ"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Het IP adres van de zender van het koperkoppelvlak. Deze is optioneel. Deze dient altijd 192.168.1.57 te zijn.")])])],1)],1),_c('v-row',[_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"id":"koperKoppelvlakAddBtn","disabled":!_vm.valid,"depressed":"","color":"primary"},on:{"click":_vm.addKoperKoppelvlak}},[_vm._v("Toevoegen")])],1),_c('v-row',{staticClass:"mx-0 mt-6"},[(_vm.tooManyItems)?_c('span',{staticClass:"warning--text",attrs:{"id":"koperKoppelvlakTooManyItemsWarning"}},[_vm._v("Maximum number of koperkoppelvlakken reached")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Profibus configuratie")]),_c('ProfibusConfig',{attrs:{"profibuses":_vm.profibuses,"show-hardware-id":true,"show-delete-button":true},on:{"profibusListUpdated":_vm.onListUpdated}}),_c('v-form',{ref:"form",staticClass:"mt-6",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"profibusId","rules":[_vm.idRules],"counter":20,"label":"Id","required":"","filled":""},model:{value:(_vm.profibusId),callback:function ($$v) {_vm.profibusId=$$v},expression:"profibusId"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("ID moet uniek zijn. Het ID wordt gebruikt om profibussen te onderscheiden en te herkennen in het testrapport.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"id":"profibusHost","rules":_vm.hostRules,"items":_vm.hostOptions,"label":"Host","required":"","filled":""},model:{value:(_vm.host),callback:function ($$v) {_vm.host=$$v},expression:"host"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Het IP adres van de profibus is op te zoeken in de geleverde IP lijst."),_c('br'),_vm._v(".21 is hoofdbaanweg rechts"),_c('br'),_vm._v(".22 is hoofdbaanweg links"),_c('br'),_vm._v(".23 is verbindingsweg rechts"),_c('br'),_vm._v(".24 is verbindingsweg links")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"id":"profibusIcpconId","filled":"","items":_vm.icpconIdOptions,"return-object":false,"label":"ICP CON ID","required":""},model:{value:(_vm.icpconId),callback:function ($$v) {_vm.icpconId=$$v},expression:"icpconId"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Het ID van de ICP CON waar de profibus op aangesloten is. In vrijwel alle gevallen zal dit icpcon-1 zijn (dit is de ICP CON met ip 192.168.1.3).")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"profibusIcpconRelaisNummer","rules":_vm.icpconRelaisNummerRules,"label":"ICP CON Relais Nummer","required":"","filled":""},model:{value:(_vm.icpconRelaisNummer),callback:function ($$v) {_vm.icpconRelaisNummer=$$v},expression:"icpconRelaisNummer"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Duidt aan welk relais van de ICP CON gebruikt wordt om de profibus te herstarten. Dit is te vinden in het kastenpakket.")])])],1)],1),_c('v-row',[_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"id":"profibusAddBtn","disabled":!_vm.valid,"depressed":"","color":"primary"},on:{"click":_vm.addProfibus}},[_vm._v("Toevoegen")])],1),_c('v-row',{staticClass:"mx-0 mt-6"},[(_vm.tooManyItems)?_c('span',{staticClass:"warning--text",attrs:{"id":"profibusTooManyItemsWarning"}},[_vm._v("Maximum number of profibuses reached")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
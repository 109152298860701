<template>
  <div>
    <v-data-table
    id="sld4sTable"
    :headers="headers"
    :items="sld4s"
    class="elevation-1"
    item-key="id"
    :items-per-page="-1"
    :hide-default-footer="true"
    >
    <template v-slot:[`item.hardwareId`]="{ item }">
        <td :id="createElementId('id', item)" class="text-start">
            {{item.hardwareId}}
        </td>
    </template>
    <template v-slot:[`item.host`]="{ item }">
        <td :id="createElementId('host', item)" class="text-start">
            {{item.host}}
        </td>
    </template>
    <template v-slot:[`item.port`]="{ item }">
        <td :id="createElementId('port', item)" class="text-start">
            {{item.port}}
        </td>
    </template>
    <template v-slot:[`item.rijstroken`]="{ item }">
        <td :id="createElementId('rijstroken', item)" class="text-start">
            {{item.rijstroken}}
        </td>
    </template>
    <template v-slot:[`item.icpconId`]="{ item }">
        <td :id="createElementId('icpconId', item)" class="text-start">
            {{item.icpconId}}
        </td>
    </template>
    <template v-slot:[`item.icpconRelaisNummer`]="{ item }">
        <td :id="createElementId('icpconRelaisNummer', item)" class="text-start">
            {{item.icpconRelaisNummer}}
        </td>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
        <v-icon
        :id="createElementId('delete', item)"
        small
        @click="deleteItem(item)"
        >
        mdi-delete
        </v-icon>
        </template>
    </v-data-table>
  </div>
</template>

<script>
   /**
    *  The Sld4Config component is a table where each row contains the configuration of an sld4 fl-comserver.
    */
  export default {
    name: 'Sld4Config',

    data: () => ({
       /**
        *  headers: columns displayed in the table
        */
        headers: [
            {
                text: 'Host', 
                value: 'host', 
                sortable: false },
            { 
                text: 'Poort',
                value: 'port',
                sortable: false,
            },
            {
                text: 'Aantal Lusparen',
                value: 'rijstroken',
                sortable: false,
            }, 
            {
                text: 'ICP CON ID',
                value: 'icpconId',
                sortable: false,
            },
            {
                text: 'ICP CON Relais Nummer',
                value: 'icpconRelaisNummer',
                sortable: false
            }
        ],
    }),

    /**
     *  vue-prop: sld4s - the array of sld4s where each sld4 is a row in the table
     *  vue-prop: showHardwareId - boolean to determine whether the hardwareId should be shown as a column
     *  vue-prop: showDeleteButton - boolean to determine whether the delete button should be shown
     *  A single sld4 config is used on the sld4 page, above the tests of that specific sld4.
     *  A list of sld4 config is used in the configuration page.
     */
    props: {
        sld4s: Array,
        showHardwareId: Boolean,
        showDeleteButton: Boolean
    },

    watch: {
        /**
         *  vue-event: sld4sListUpdated - when the list of sld4s is changed the new list is sent to parent (Config.vue)
         */
        sld4s: function (updatedList) {
            this.$emit('sld4sListUpdated', updatedList);
        }
    },

    /**
     *  The 'Id' column is only added to the table when the config shows a list of sld4s.
     *  The delete icon is only added to the table when the config shows a list of sld4s.
     */
    created () {
        if (this.showHardwareId) {
          this.headers.unshift({
            text: 'Id',
            align: 'start',
            sortable: false,
            value: 'hardwareId',
          });
        }
        if (this.showDeleteButton) {
          this.headers.push({
            text: '',
            sortable: false,
            value: 'delete',
          });
        }
    },

    methods: {
        /**
         *  Button click deleteItem
         *  Deletes an sld4 from the array of sld4s.
         *  This removes that sld4 config from the table.
         */
        deleteItem (item) {
            this.sld4s.splice(this.sld4s.indexOf(item), 1)
        },

        /**
         *  Method to create a unique HTML element id used for automatic testing
         */
        createElementId(element, item) {
            return element+"-"+item.hardwareId;
        }
    }
  }
</script>
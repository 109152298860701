<template>
  <div> 

    <h2>Profibus configuratie</h2>

    <ProfibusConfig :profibuses="profibuses" :show-hardware-id="true" :show-delete-button="true" v-on:profibusListUpdated="onListUpdated"/>

    <v-form ref="form" v-model="valid" class="mt-6">
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="profibusId"
              v-model="profibusId"
              :rules="[idRules]"
              :counter="20"
              label="Id"
              required
              filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>ID moet uniek zijn. Het ID wordt gebruikt om profibussen te onderscheiden en te herkennen in het testrapport.</span>
            </v-tooltip>
          </span>

          <v-col cols="12" md="2">
            <v-select
              id="profibusHost"
              v-model="host"
              :rules="hostRules"
              :items="hostOptions"
              label="Host"
              required filled
            ></v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het IP adres van de profibus is op te zoeken in de geleverde IP lijst.<br>.21 is hoofdbaanweg rechts<br>.22 is hoofdbaanweg links<br>.23 is verbindingsweg rechts<br>.24 is verbindingsweg links</span>
            </v-tooltip>
          </span>
          <v-col cols="12" md="2">
            <v-select
                id="profibusIcpconId"
                filled
                v-model="icpconId"
                :items="icpconIdOptions"
                :return-object="false"
                label="ICP CON ID"
                required >
            </v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het ID van de ICP CON waar de profibus op aangesloten is. In vrijwel alle gevallen zal dit icpcon-1 zijn (dit is de ICP CON met ip 192.168.1.3).</span>
            </v-tooltip>
          </span>
          <v-col cols="12" md="2">
            <v-text-field
              id="profibusIcpconRelaisNummer"
              v-model="icpconRelaisNummer"
              :rules="icpconRelaisNummerRules"
              label="ICP CON Relais Nummer"
              required filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Duidt aan welk relais van de ICP CON gebruikt wordt om de profibus te herstarten. Dit is te vinden in het kastenpakket.</span>
            </v-tooltip>
          </span>
        </v-row>

        <!-- MUSsen and Beeldstanden configuration is commented out because it is not used now, but might be used in the future. -->
        
        <!-- Config MUSsen -->
        <!-- <v-row v-for="mus in mussen" :key="mus.id">
            <v-col cols="12" md="2">
                <v-checkbox
                v-model="mus.checked"
                :label="mus.id"
                ></v-checkbox>
            </v-col>
            <v-col cols="12" md="2">
                <v-select  
                    filled
                    :disabled="!mus.checked"
                    v-model="mus.rotatiePaneel"
                    :items="rotatiePaneel"
                    :item-value="rotatiePaneel.value"
                    :item-text="rotatiePaneel.text"
                    :return-object="false"
                    label="Rotatiepaneel">
                </v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                v-model="mus.pbSlaveAddress"
                :disabled="!mus.checked"
                label="Profibus Slave Address"
                :rules="slaveAddressRules"
                filled
                ></v-text-field>
            </v-col>
        </v-row> -->

        <!-- Config beeldstanden -->
        <!-- <v-row dense v-for="(beeldstand, index) in beeldstanden" :key="beeldstand.id">
            <v-col cols="12" md="2">
                <v-list-item-title v-if="index === 0" class="my-4">Beeldstanden:</v-list-item-title>
            </v-col>
            <v-col cols="12" md="1">
                <v-img
                    :alt="beeldstand.id"
                    :src="require(`../assets/${beeldstand.image}.svg`)"
                    transition="scale-transition"
                    width="46"
                    class="mt-1"
                />
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field
                v-model="beeldstand.groepCode"
                :rules="requiredRules"
                label="Groep"
                required filled
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field
                v-model="beeldstand.beeldCode"
                :rules="requiredRules"
                label="Beeldcode"
                required filled
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
                <v-text-field
                v-model="beeldstand.variatieCode"
                :rules="requiredRules"
                label="Variatie"
                required filled
                ></v-text-field>
            </v-col>
        </v-row> -->

        <v-row >
          <v-btn id="profibusAddBtn" :disabled="!valid" class="mt-5 ml-3" depressed color="primary" @click="addProfibus">Toevoegen</v-btn>
        </v-row>
        <v-row class="mx-0 mt-6" >
          <span id="profibusTooManyItemsWarning" v-if="tooManyItems" class="warning--text">Maximum number of profibuses reached</span>
        </v-row>
    </v-form>        
  </div>
</template>

<script>
/**
  *  The ProfibusConfigForm component consists of a form with fields to configure a profibus.
  *  component: ProfibusConfig - summary table of profibuses in the current iwks configuration
  */
import ProfibusConfig from '@/components/ProfibusConfig.vue'

  export default {
    name: 'profibusConfigForm',

   /**
     *  valid: boolean to store whether the form is valid (i.e. has no errors)
     *  profibusId: string containing the iRose ID
     *  host: string containing the host address
     *  hostOptions: the possible host options
     *  hostRules: rules used to determine whether the field is a valid host
     *  port: string containing the port number
     *  portRules: rules used to determine whether the field is a valid port number
     *  msiAantal: number of MSI's connected to the iWKS
     *  msiRules: rules used to determine whether the field is a valid number of connected MSI's
     *  requiredRules: rules used to determine whether a required field is filled out
     *  mussen: mussen possibly connected to the profibus 
     *           with String Id, boolean whether it is connected or not, boolean whether it is a rotatiepaneel or not, integer defining the pbSlaveAddress
     *  slaveAddressRules: rules used to determine whether the field is a valid profibus slave address
     *  rotatiePaneel: boolean with corrsponding text values
     *  beeldstanden: the used beeldstanden in the test with string Id, integer groepcode, beeldcode and variatiecode, and string of the corresponding image to show
     *  profibuses: array of profibuses currently added to the configuration
     *  tooManyItems: boolean used to show an error message when the maximum number of profibuses in the list is reached
     */
    data: () => ({
        valid: true,
        profibusId: 'pb1',
        host: '192.168.1.21',
        hostOptions: ['192.168.1.21', '192.168.1.22', '192.168.1.23', '192.168.1.24'],
        hostRules: [
            v => !!v || 'Host is required',
            v => /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v) || 'Host must be a valid ip address',
        ],
        port: '10001',
        portRules: [
            v => !!v || 'Port is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) > 0 && Number(v) < 65536|| "The value must be a valid port number",
        ],
        msiAantal: 4,
        msiRules: [
            v => !!v || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) > 0 && Number(v) < 9|| "Valid values are 1-8",
        ],
        requiredRules: [
            v => !!v || 'Field is required',
        ],

        mussen: [
            {
                id: 'Mus1', 
                checked: false, 
                rotatiePaneel: true,
                pbSlaveAddress: 31 //Default Links is 31,32. Default Rechts is 21,22. --> TODO uit OS id halen
            },
            {
                id: 'Mus2', 
                checked: false, 
                rotatiePaneel: true,
                pbSlaveAddress: 32 //Default Links is 31,32. Default Rechts is 21,22. --> TODO uit OS id halen
            },
        ], 

        slaveAddressRules: [
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) >= 1 && Number(v) <= 99|| "Valid values are 1-99",
        ],

        rotatiePaneel: [
            {value: true, text: "true"},
            {value: false, text: "false"},
        ],

        beeldstanden: [
            { beeldstandId: 'BL', groepCode: 255, beeldCode: 1, variatieCode: 1, image: 'msi_blank'},
            { beeldstandId: '(50)', groepCode: 1, beeldCode: 50, variatieCode: 3, image: 'msi_50rr'},
            { beeldstandId: '*70*', groepCode: 1, beeldCode: 70, variatieCode: 2, image: 'msi_70fl'},
            { beeldstandId: '100', groepCode: 1, beeldCode: 100, variatieCode: 1, image: 'msi_100'},
            { beeldstandId: 'X', groepCode: 2, beeldCode: 4, variatieCode: 1, image: 'msi_X'},
        ],
        
        profibuses: [],
        tooManyItems: false,
        icpconId: 'icpcon-1',
        icpconIdOptions: ['icpcon-1', 'icpcon-2'],
        icpconRelaisNummer: 1,
        icpconRelaisNummerRules: [
            v => v!=="" || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) >= 0 && Number(v) <= 7|| "Valid values are 0-7",
        ]
    }),

    methods: {
      addProfibus() {
        if (!this.$refs.form.validate()) return;
        const profibus = {
          profibusId: this.profibusId,
          host: this.host,
          port: this.port,
          msiAantal: this.msiAantal,
          mussen: this.mussen.filter((x) => x.checked == true),
          beeldstanden: this.beeldstanden,
          icpconId: this.icpconId,
          icpconRelaisNummer: this.icpconRelaisNummer,
        };
        this.profibuses.push(profibus);
        this.resetProfibusInputFields();
      },

      resetProfibusInputFields() {
        this.profibusId = this.newProfibusId();
        this.host = '192.168.1.21';
        this.port = '10001';
        this.icpconId = "icpcon-1"
        this.icpconRelaisNummer = 1;
        this.msiAantal = 4;

        this.mussen = [
            {
                id: 'Mus1', 
                checked: false, 
                rotatiePaneel: true,
                pbSlaveAddress: 31 //Default Links is 31,32. Default Rechts is 21,22. --> TODO uit OS id halen
            },
            {
                id: 'Mus2', 
                checked: false, 
                rotatiePaneel: true, 
                pbSlaveAddress: 32 //Default Links is 31,32. Default Rechts is 21,22. --> TODO uit OS id halen
            },
        ];

        this.beeldstanden = [
            { beeldstandId: 'BL', groepCode: 255, beeldCode: 1, variatieCode: 1, image: 'msi_blank'},
            { beeldstandId: '(50)', groepCode: 1, beeldCode: 50, variatieCode: 3, image: 'msi_50rr'},
            { beeldstandId: '*70*', groepCode: 1, beeldCode: 70, variatieCode: 2, image: 'msi_70fl'},
            { beeldstandId: '100', groepCode: 1, beeldCode: 100, variatieCode: 1, image: 'msi_100'},
            { beeldstandId: 'X', groepCode: 2, beeldCode: 4, variatieCode: 1, image: 'msi_X'},
        ];
      },
      /**
       *  Method used to generate a new unique id as default profibus id in the config field
       */
      newProfibusId() {
        var nr = 0;
        var id = "pb1";
        while (this.profibuses.some(el => el.profibusId === id)) {
          nr++;
          id = 'pb' + nr.toString();
        }
        return id;
      },

      /**
       *  The list of profibuses is modified from within this component (when adding a profibus to the list) and from the ProfibusConfig component (when deleting profibuses from the list).
       *  Therefore, checking the size of the current number of profibuses in the list should be done in this method, which is a vue event triggered from its child component ProfibusConfig.
       *  vue-event: iRosesListUpdated -  when the list of iRoses is updated in the iRoseConfig the new updated list is sent to the Config vue. 
       */
      onListUpdated() {
        this.$emit('profibusListUpdated', this.profibuses);
        if (this.profibuses.length >= 4) {
          this.valid = false;
          this.tooManyItems = true;
        } else {
          this.valid = true;
          this.tooManyItems = false;
        }
      },
      /**
       *  Method used to determine the rules for the id field in the form.
       *  Other rules are determined as arrays in data objects, but there you cannot access other data objects and we need to access the profibuses array
       */
      idRules( value ) {
        if (value.length == 0 || value === "" ) {
          return "Id is required";
        }

        if (value.length > 20 ) {
          return "The value must be less than 20 characters"
        }

        if (this.profibuses.some(el => el.profibusId === value)) {
          return "Identifier must be unique";
        }

        return true;
      }
    },
    components: {
      ProfibusConfig
    }
  }
</script>
<template>
  <div>
    <h2>Algemene configuratie</h2>
    <v-form ref="form" class="mt-6" lazy-validation>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
              id="project"
              v-model="generalConfiguration.project"
              :rules="textFieldRules"
              :counter="50"
              label="Projectnaam/Blok/Fase"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
              id="alias"
              v-model="generalConfiguration.alias"
              :rules="textFieldRules"
              :counter="50"
              label="Kast-naam"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>Vul hier een alias voor de kast in om de testsessie later makkelijker terug te vinden.</span>
          </v-tooltip>
        </span>
        <v-col cols="12" md="2">
          <v-text-field
              id="locatie"
              v-model="generalConfiguration.location"
              :rules="textFieldRules"
              :counter="50"
              label="Locatie"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>Vul hier de locatie van de HW-leverancier in.</span>
          </v-tooltip>
        </span>
        <v-col cols="12" md="2">
            <v-select
            id="stationType"  
            filled
            v-model="generalConfiguration.stationType"
            :items="stationTypeOptions"
            :rules="stationTypeRules"
            :return-object="false"
            label="Type kast"
            @change="onConfigurationChanged"
            required >
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
              id="prodIpAddress"
              v-model="generalConfiguration.prodIpAddress"
              :rules="textFieldRules"
              :counter="50"
              label="Productie ip-adres"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Vul hier het productie ip-adres in.</span>
            </v-tooltip>
          </span>
        <v-col cols="12" md="2">
          <v-text-field
              id="prodGateway"
              v-model="generalConfiguration.prodGateway"
              :rules="textFieldRules"
              :counter="50"
              label="Productie gateway"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Vul hier de productie gateway in.</span>
            </v-tooltip>
          </span>
        <v-col cols="12" md="2">
          <v-text-field
              id="prodSubnet"
              v-model="generalConfiguration.prodSubnet"
              :rules="textFieldRules"
              :counter="50"
              label="Productie subnet"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Vul hier het productie subnet in.</span>
            </v-tooltip>
          </span>
      </v-row>

      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
              id="opsIpAddress"
              v-model="generalConfiguration.opsIpAddress"
              :rules="textFieldRules"
              :counter="50"
              label="Beheer ip-adres"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Vul hier het beheer ip-adres in.</span>
            </v-tooltip>
          </span>
        <v-col cols="12" md="2">
          <v-text-field
              id="opsGateway"
              v-model="generalConfiguration.opsGateway"
              :rules="textFieldRules"
              :counter="50"
              label="Beheer gateway"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Vul hier de beheer gateway in.</span>
            </v-tooltip>
          </span>
        <v-col cols="12" md="2">
          <v-text-field
              id="opsSubnet"
              v-model="generalConfiguration.opsSubnet"
              :rules="textFieldRules"
              :counter="50"
              label="Beheer subnet"
              @change="onConfigurationChanged"
              filled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Vul hier het beheer subnet in.</span>
            </v-tooltip>
          </span>
      </v-row>
    </v-form>        
  </div>
</template>

<script>
/**
  *  The GeneralConfigForm component consists of a form with general configuration fields.
  */
  export default {
    name: 'GeneralConfigForm',

    /**
     *  valid: boolean to store whether the form is valid (i.e. has no errors)
     *  generalConfiguration: dictionary with all general configuration values
     *  textFieldRules: rules for text fields
     */
    data: () => ({
        valid: false,
        generalConfiguration: {
          "project" : "",
          "alias" : "",
          "location" : "",
          "stationType" : "iWKS",
          "opsIpAddress" : "",
          "opsGateway" : "",
          "opsSubnet" : "",
          "prodIpAddress" : "",
          "prodGateway" : "",
          "prodSubnet" : ""
        },
        stationTypeOptions: ['iWKS', 'iDS'],
        stationTypeRules: [
            v => !!v || 'Field is required',
        ],
        textFieldRules: [
            v => !!v || 'Field is required',
            v => (v && v.length <= 50) || "The value must be less than 50 characters"
        ]
    }),

    methods: {
      /**
       * Event for change of one of the fields
       */
      onConfigurationChanged() {
        this.$emit('generalConfigurationChanged', this.generalConfiguration);
      },
      /**
       * Validate the form
       * @returns {boolean} true if form is valid, else false
       */
      validate() {
        return this.$refs.form.validate();
      }
    },
  }
</script>
<template>
  <div> 
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="3" >
          <v-menu
              id="menuFromDate"
              v-model="menuFromDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="menuFromDateTextfield"
                  v-model="dateFromFormatted"
                  label="Filter from date"
                  prepend-icon="mdi-calendar"
                  clearable
                  @click:clear="clearDateFrom"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                id="datepickerFromDate"
                v-model="dateFrom"
                no-title
                @input="processPickedDate('from')"
                :pickerDate.sync="pickerFromDate"
              ></v-date-picker>
            </v-menu>
        </v-col>

        <v-col cols="12" sm="6" md="3" >
          <v-menu
              id="menuToDate"
              v-model="menuToDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="menuToDateTextfield"
                  v-model="dateToFormatted"
                  label="Filter to date"
                  prepend-icon="mdi-calendar"
                  clearable
                  @click:clear="clearDateTo"
                  readonly
                  :rules="dateValidation"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                id="datepicketToDate"
                v-model="dateTo"
                no-title
                @input="processPickedDate('to')"
                :pickerDate.sync="pickerToDate"
              ></v-date-picker>
            </v-menu>
        </v-col>
      </v-row>

      <v-data-table
        id="testSessionList"
        :headers="headers"
        :items="allTestSessions"
        :items-per-page="5"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1 cursor"
        item-key="id"
        @click:row="onRowClick"
        :single-select=true
        >
        <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ item.createdAt | parseDateTime }}</span>
        </template>
        <template v-slot:item.controls="props">
          <v-btn :class="'delete-button'" :id="props.item.id" v-on:click.stop plain @click="deleteTestSessionPrompt(props.item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        </v-data-table>
    </v-container>
  </div>
</template>

<script>
/**
 *  Component containing the list of previous test sessions of the logged in user.
 */
import gql from 'graphql-tag'
import {
  ZonedDateTime
} from "@js-joda/core";
import util from "@/components/util"
import "@js-joda/timezone";

  export default {
    name: 'iRoseTesten',
    apollo: {
      /**
       *  Query to retrieve the available test sessions for this logged in user. 
       */
      allTestSessions: gql`query {
        allTestSessions {id, createdAt, iwks {macAddress}, iwksConfiguration {general {alias}} }
      }`,
    },

    /**
     *  selected: currently selected test session from the list
     *  dateFrom: the from date used to filter the test sessions in the list
     *  dateFromFormatted: the formatted string of the date from field
     *  menuFromDate: boolean to determine whether the calendar popup is visible or not for the from date field
     *  dateTo: the to date used to filter the test sessions in the list
     *  dateToFormatted: the formatted string of the date to field
     *  menuToDate: boolean to determine whether the calendar popup is visible or not for the to date field
     *  dateValidation: boolean used to show an error message if the selected dates for the filter are not possible
     *                  boolean is updated whenever the to and from datepickers are updated or the fields are cleared
     *  pickerFromdate: value used to reset the datepicker to open at the current date after clearing the text field
     *  pickerTodate: value used to reset the datepicker to open at the current date after clearing the text field
     */
    data: () => ({
        selected: [],
        dateFrom: '',
        dateFromFormatted: '',
        menuFromDate: false,
        dateTo: '',
        dateToFormatted: '',
        menuToDate: false,
        dateValidation: [true],
        pickerFromDate: "",
        pickerToDate: "",
        sortBy: 'createdAt',
        sortDesc: true
    }),

    computed: {
      /**
       *  headers: columns displayed in the table
       */
      headers () {
        return [
          {
              text: 'Sessie ID',
              align: 'start',
              sortable: false,
              value: 'id',
          },
          {
              text: 'Kast-naam',
              value: 'iwksConfiguration.general.alias',
              sortable: true
          },
          {
              text: 'MAC-adres',
              value: 'iwks.macAddress',
              sortable: true
          },
          { 
              text: 'Datum geïnitialiseerd', 
              value: 'createdAt', 
              /**
               *  Filter used to filter out test sessions older than the date from date, and newer than the date to date.
               */
              filter: value => {
                if (!this.dateFrom && !this.dateTo) return true;
                let zonedDateTimeString = ZonedDateTime.parse(value).toLocaleString();

                let date = zonedDateTimeString.split('T')[0];

                if (this.dateFrom && this.dateTo) return date <= this.dateTo && date >= this.dateFrom;

                if (this.dateTo) return date <= this.dateTo;

                if (this.dateFrom) return date >= this.dateFrom;
                
                return true;
              },
              sortable: true,
          },
          {
            text: "",
            value: "controls",
            sortable: false,
          },
        ]
      }
    },

    /**
     *  Watch dateTo and dateFrom such that when they change you immediately format the date 
     */
    watch: {
      dateTo () {
        this.dateToFormatted = this.formatDate(this.dateTo)
      },
      dateFrom () {
        this.dateFromFormatted = this.formatDate(this.dateFrom)
      },
    },

    filters: {
      parseDateTime: function (zonedDateTime) {
        return util.parseDateTime(zonedDateTime);
      }
    },

    methods: {
      /**
       *  Row click onRowClick
       *  When a row is clicked the test session Id is taken from the item and stored in the Vuex store.
       *  Then reroute to the AMT Testen page
       */
      onRowClick(item, row) {
        this.selected = item;
        row.select(true);  
        this.$store.commit('setTestSessionId', this.selected.id);
        this.$router.push({name: 'AMT Testen'});
      },

      /**
       *  Method used to format the date string
       */
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },

      /**
       *  When a value in the datepicker is selected, close the datepicker and call the dateRule to check if values are still valid.
       */
      processPickedDate (field) {
        if (field === 'from') {
          this.menuFromDate = false;
        } else if (field === 'to') {
          this.menuToDate = false;
        }

        this.dateRule();
      },

      /**
       *  Rules used to determine whether date fiels are valid (i.e. the to date can not be older than the from date)
       *  Boolean 'dateValidation' is used as a rule on the text-field menuToDateTextfield.
       */
      dateRule () {
        if (this.dateFrom && this.dateTo && this.dateFrom > this.dateTo) {
          this.dateValidation =["Filter vanaf datum moet eerder zijn dan de filter tot datum!"];
        } else {
          this.dateValidation =[true];
        }
      }, 
      /**
       *  Icon click used to clear the date from field
       */
      clearDateFrom(){
        const today = new Date().toISOString().split('T')[0];
        this.pickerFromDate = today;
        this.dateFrom = "";
        this.dateRule();
      },
      /**
       *  Icon click used to clear the date to field
       */
      clearDateTo(){
        const today = new Date().toISOString().split('T')[0];
        this.pickerToDate = today;
        this.dateTo = "";
        this.dateRule();
      },

      /**
       * Show prompt to delete test session
       * @param testSession Test session to delete
       */
      deleteTestSessionPrompt(testSession) {
        this.$fire({
          title: "Weet je zeker dat je deze testsessie wilt verwijderen?",
          html: `<b>Kast-naam:</b> ${testSession.iwksConfiguration.general.alias} <br>
                 <b>ID:</b> ${testSession.id}<br>
                 <b>Datum geïnitialiseerd:</b> ${util.parseDateTime(testSession.createdAt)}`,
          showCancelButton: true,
          confirmButtonText:'Verwijder testsessie',
          cancelButtonText:'Annuleren',
          focusConfirm: false,
          focusCancel: false,
          reverseButtons: true,
        }).then((result) => {
              if (result.value) {
                this.deleteTestSession(testSession.id);
              }
        });
      },

      /**
       * Delete test session
       * @param testSessionId id of test session to delete
       */
      deleteTestSession(testSessionId) {
        this.$apollo.mutate({
          mutation: gql` mutation deleteTestSessionMutation ($testSessionId: UUID!) {
            deleteTestSession(testSessionId: $testSessionId)
          }`,
          variables: {
            testSessionId: testSessionId
          }
        }).then(() => {
          console.log("Successfully deleted test session with id: " + testSessionId);
          // Set currentTestSessionId to empty string if deleted test is set as current
          if (this.$store.getters.getCurrentTestSessionId === testSessionId) {
            this.$store.commit('setTestSessionId', '');
          }
        }).catch((error) => {
          console.error("An error has occurred while trying to delete test session with id: " + testSessionId + "\n" + error)
        }).finally(() => this.$apollo.queries.allTestSessions.refetch())
      }
    },

    /**
     *  Refetch the available test sessions after the page is fully loaded.
     *  After navigating back to the homepage newly created test sessions were not shown, only after refreshing the page.
     *  This refetch on page load fixes that. 
     */
    mounted() {
      this.$apollo.queries.allTestSessions.refetch();
    },
  }
</script>

<style scoped>
  .cursor >>> tbody tr :hover {
    cursor: pointer;
  }
</style>

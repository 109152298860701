<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
          <v-img
            alt="iWKS Logo"
            contain
            src="./assets/logo.png"
            transition="scale-transition"
            width="120"
            class="ma-2"
          />
        <v-divider></v-divider>

        <v-list nav class="pa-0">
          <v-list-item
              class="ma-0 py-1"
              v-for="item in items"
              :key="item.title"
              :to="item.route"
              link
              v-show="isVisible(item.title)"
          >
            <v-list-item-content>
                {{ item.title }}
            </v-list-item-content>

          </v-list-item>

        </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <span>{{this.$route.name}}</span>
      <v-spacer></v-spacer>
        <span class="mr-2">User: {{keycloak.idTokenParsed.preferred_username}} ({{keycloak.resourceAccess[config.VUE_APP_KEYCLOAK_CLIENT_ID].roles[0]}})</span>
        <v-icon @click="onLogout">mdi-logout</v-icon>
    </v-app-bar>

    <v-main>
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
/**
 *  The main App, which content will be present on all pages. 
 *  Keycloak is configured here. It also contains the menu.
 *  component: router-view - contains the pages (/Home, /Config etc). When the URL is changed, only this part is replaced, the menu stays. 
 */
import gql from 'graphql-tag'

export default {
  name: 'app',
  props: ['keycloak', 'config'],
  apollo: {
    /**
     *  query to retrieve some data (i.e. the id field) of the first tests of iRose, Sld4, LD16, Profibus and UPS.
     *  variable: currently selected test session ID.
     *  skip: when no test session is selected there is no need to run the query.
     */
    $subscribe: {
      subs: {
        query: gql`subscription myMenuSubscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            tests { id, hardwareId, testType }
          }
        }`,
        variables () {
          // Use vue reactive properties here
          return {                
            id: this.testSessionId
          }
        },
        skip() {
          return this.testSessionId==='';
        },
        // Result hook
        // Don't forget to destructure `data`
        result ({ data }) {
          this.data = data;
        },
        error({error}) {
          console.log("an error occured : " + error); //TODO this does not work.
        }
      },
    },
  },
  data: () => ({
    /**
     *  items: pages added to menu item. (Not all pages of the router are added, for example the config page should not be in the menu.)
     *  drawer: menu on the left side of the screen. If smaller screen sized (or minimized by user) menu is not shown here, but only as hamburger menu.
     *  data: used to store the result of the apollo query.
     */
    items: [
      { title: 'Home', route: '/' },
      { title: 'AMT Testen', route: '/amttesten'},
      { title: 'LAN Switch Testen', route: '/lanswitchtesten'},
      { title: 'ICP CON Testen', route: '/icpcontesten'},
      { title: 'iRose Testen', route: '/irosetesten' },
      { title: 'SLD4 Testen', route: '/sld4testen' },
      { title: 'LD16 Testen', route: '/ld16testen' },
      { title: 'Falcon Radar Testen', route: '/falconradartesten' },
      { title: 'Koper Koppelvlak Testen', route: '/koperkoppelvlaktesten' },
      { title: 'Profibus Testen', route: '/profibustesten' },
      { title: 'UPS Testen', route: '/upstesten' },
      { title: 'Testresultaten', route: '/testresultaten' }
    ],
    drawer: true,
    data: {}
  }),
  computed: {
    /**
     *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
     */
    testSessionId() {
      return this.$store.getters.getCurrentTestSessionId;
    }
  },
  methods: {
    /**
     *  Method to determine whether menu items should be enabled or disabled.
     *  If the first test of iRose, Sld4, LD16, Profibus or UPS contains some data (the result of the Apollo query) then the test exists and the menu item of the corresponding page should be enabled.
     */
    isVisible(title) {
      if (title === "Home") {
        return true;
      }

      if (this.$store.getters.getCurrentTestSessionId === '') return false;

      if (title === "AMT Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "AMT_CONNECT").length!==0) return true;

      if (title === "LAN Switch Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "LAN_SWITCH_PING_1").length!==0) return true;

      if (title === "ICP CON Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "ICP_CON_CONNECT").length!==0) return true;

      if (title === "iRose Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "IROSE2_CONNECT").length!==0) return true;

      if (title === "SLD4 Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "SLD4_CONNECT").length!==0) return true;

      if (title === "LD16 Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "LD16_CONNECT").length!==0) return true;

      if (title === "Falcon Radar Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "FALCON_RADAR_PING").length!==0) return true;

      if (title === "Koper Koppelvlak Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "KOPER_KOPPELVLAK_PLC_PING").length!==0) return true;

      if (title === "Profibus Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "PROFIBUS_GATEWAY_CONNECT").length!==0) return true;
      
      if (title === "UPS Testen" && this.data.testSessionChanged && this.data.testSessionChanged.tests.filter(test => test.testType == "UPS_COMMUNICATIE").length!==0) return true;

      if (title === "Testresultaten") return true;

      return false;
    },
    onLogout() {
      /**
       *  Button lick onLogout
       *  Clear the currently selected test session ID
       *  Log out from keycloak
       */
      this.$router.push({name: 'Home'});
      sessionStorage.clear();
      this.keycloak.logout();
    }
  }
}
</script>

<style scoped>
  a.v-list-item--active{
    color: #1976d2;
}
</style>